import ApiService from "@/core/services/ApiService";

export default function useReport() {

const getAll = async () => {
    return await ApiService.get("tax_report_all");
  };

  const getFinish = async () => {
    return await ApiService.get("tax_report_finish");
  };


  const getProcessing = async () => {
    return await ApiService.get("tax_report_processing");
  };


  const getWait = async () => {
    return await ApiService.get("tax_report_wait");
  };

  const getExcel = async () => {
    return await ApiService.get("tax_report_excel");
  };


  



  

  

  return {
    getAll,
    getFinish,
    getProcessing,
    getWait,
    getExcel
  };
}
