<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="row g-5 g-xl-8 mb-5">
      <div class="col-12">
        <!--begin::Charts Widget 1-->
        <div class="card card-xl-stretch mb-xl-8">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <!--begin::Title-->
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">แจ้งเตือนการชำระภาษี </span>
            </h3>
            <button @click="excel" type="button" class="m-2 btn btn-outline-success">
              Excel <i class="bi excel bi-file-earmark-excel"></i>
            </button>
            <!--end::Title-->
            <!--begin::Toolbar-->
            <div class="card-toolbar"></div>
            <!--end::Toolbar-->
          </div>
        </div>
        <!--end::Charts Widget 1-->
      </div>
    </div>
    <!--end::Row-->
    <!--begin::Row-->
    <div class="row g-5 g-xl-8 mb-5">
      <All></All>
      <Wait></Wait>
      <Processing></Processing>
      <Finish></Finish>
    </div>
  </div>
</template>

<script>
import All from "@/components/tax/widget/All.vue";
import Wait from "@/components/tax/widget/Wait.vue";
import Processing from "@/components/tax/widget/Processing.vue";
import Finish from "@/components/tax/widget/Finish.vue";
import useReport from "@/core/services/api/tax/report";
import * as XLSX from "xlsx/xlsx.mjs";
import { defineComponent, onMounted, onUpdated, ref } from "vue";
import { MenuComponent, ToggleComponent, DrawerComponent } from "@/assets/js/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { checkPage } from "@/core/helpers/checkpage";
import moment from "moment";
export default defineComponent({
  name: "reports-report_all",
  components: {
    All,
    Wait,
    Processing,
    Finish,
  },
  setup() {
    onMounted(() => {
      checkPage("tax-list");
      setCurrentPageBreadcrumbs("รายงาน", ["สอบถามภาษี"]);
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });

    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    const { getExcel } = useReport();

    function dateThai(v) {
      var y = moment(v).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(v).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    const excel = () => {
      isLoading.value = true;

      getExcel().then((response) => {
        var total = response.data.data;
        let qq = [];
        for (let index = 0; index < total.length; index++) {
          qq.push({
            'หัวข้อ': total[index].title,
            'เรื่องที่สอบถาม': total[index].sub_title,
            'รายละเอียด': total[index].detail,
            'ชื่อ': total[index].user?.firstname,
            'นามสกุล': total[index].user?.lastname,
            'บ้านเลขที่': total[index].user?.numberhome,
            'หมู่': total[index].user?.moo,
            'ซอย': total[index].user?.alleyway,
            'ถนน': total[index].user?.road,
            'ตำบล': total[index].user?.district,
            'อำเภอ': total[index].user?.amphoe,
            'จังหวัด': total[index].user?.province,
            'รหัสไปรษณีย์': total[index].user?.zipcode,
            'เบอร์โทร': total[index].user?.tel,
            'วันที่แจ้ง': dateThai(total[index].created_at),
            'สถานะ': total[index].status,
            'link สำหรับดาวน์โหลดแบบฟอร์มภาษี': total[index].link_dowload
          });
        }

        const workSheet_total = XLSX.utils.json_to_sheet(qq);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet_total, "ทั้งหมด");

        XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workBook, "Report.xlsx");
        isLoading.value = false;
      });
    };

    return {
      excel,
      color,
      isLoading,
    };
  },
});
</script>

<style scoped>
button.Page.Page-active {
  color: white;
}
</style>
